.slick-dots li button:before {
    color: #b7882c !important;
    opacity:1 !important;
    font-size: 12px;
}
.slick-dots li.slick-active button:before {
    color: #ed1c24 !important;
}
.slick-dots {
    position: unset;
    line-height: normal;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}