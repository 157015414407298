.example-thumb:focus-visible {
    border: none;
    outline: none;
}
.example-thumb {
    height: 48px;
    font-size: 20px;
    color: #262626;
    font-family: 'Quicksand-Medium';
    -webkit-text-stroke: 0.3px;
    cursor: pointer;
}
.horizontal-slider {
    top: -40px;
}
.example-thumb {
    opacity: 0;
}