@font-face {
    font-family: 'ZCOOLXiaoWei-Regular';
    src: url('../font/ZCOOLXiaoWei-Regular.eot');
    src: url('../font/ZCOOLXiaoWei-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/ZCOOLXiaoWei-Regular.woff2') format('woff2'),
        url('../font/ZCOOLXiaoWei-Regular.woff') format('woff'),
        url('../font/ZCOOLXiaoWei-Regular.ttf') format('truetype'),
        url('../font/ZCOOLXiaoWei-Regular.svg#ZCOOLXiaoWei-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Quicksand-Bold';
    src: url('../font/Quicksand-Bold.eot');
    src: url('../font/Quicksand-Bold.eot?#iefix') format('embedded-opentype'),
        url('../font/Quicksand-Bold.woff2') format('woff2'),
        url('../font/Quicksand-Bold.woff') format('woff'),
        url('../font/Quicksand-Bold.ttf') format('truetype'),
        url('../font/Quicksand-Bold.svg#Quicksand-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Quicksand-SemiBold';
    src: url('../font/Quicksand-SemiBold.eot');
    src: url('../font/Quicksand-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../font/Quicksand-SemiBold.woff2') format('woff2'),
        url('../font/Quicksand-SemiBold.woff') format('woff'),
        url('../font/Quicksand-SemiBold.ttf') format('truetype'),
        url('../font/Quicksand-SemiBold.svg#Quicksand-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Quicksand-Medium';
    src: url('../font/Quicksand-Medium.eot');
    src: url('../font/Quicksand-Medium.eot?#iefix') format('embedded-opentype'),
        url('../font/Quicksand-Medium.woff2') format('woff2'),
        url('../font/Quicksand-Medium.woff') format('woff'),
        url('../font/Quicksand-Medium.ttf') format('truetype'),
        url('../font/Quicksand-Medium.svg#Quicksand-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Quicksand-Regular';
    src: url('../font/Quicksand-Regular.eot');
    src: url('../font/Quicksand-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/Quicksand-Regular.woff2') format('woff2'),
        url('../font/Quicksand-Regular.woff') format('woff'),
        url('../font/Quicksand-Regular.ttf') format('truetype'),
        url('../font/Quicksand-Regular.svg#Quicksand-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Quicksand-Light';
    src: url('../font/Quicksand-Light.eot');
    src: url('../font/Quicksand-Light.eot?#iefix') format('embedded-opentype'),
        url('../font/Quicksand-Light.woff2') format('woff2'),
        url('../font/Quicksand-Light.woff') format('woff'),
        url('../font/Quicksand-Light.ttf') format('truetype'),
        url('../font/Quicksand-Light.svg#Quicksand-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

.font-family-ZCOOLXiaoWei-Regular {
    font-family: 'ZCOOLXiaoWei-Regular';
}
.font-family-Quicksand-Bold {
    font-family: 'Quicksand-Bold';
}
.font-family-Quicksand-SemiBold {
    font-family: 'Quicksand-SemiBold';
}
.font-family-Quicksand-Medium {
    font-family: 'Quicksand-Medium';
}
.font-family-Quicksand-Regular {
    font-family: 'Quicksand-Regular';
}
.font-family-Quicksand-Light {
    font-family: 'Quicksand-Light';
}
.font-family-sans-serif {
    font-family: sans-serif;
}